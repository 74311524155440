<template>
  <div class="fs0 g_nm w100 oh" style="max-width: 1920px;">
    <component :is="layout" />
  </div>
</template>
<script>
import EmptyLayout from '@/Layouts/EmptyLayout.vue';
import WebLayout from '@/Layouts/WebLayout.vue';

export default {
  name: "App",
  data() {
    return {
      layout: null,
    };
  },
  components: {
    EmptyLayout,WebLayout
  },
  watch: {
    $route(to) {
      if (to.meta.layout !== undefined) {
        this.layout = to.meta.layout
      } else {
        this.layout = "EmptyLayout"
      }
    },
  },
};
</script>
